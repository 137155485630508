<template>
  <div class="course-card">
    <div class="d-flex justify-content-between">
      <div class="w-98">
        <CRow class="">
          <CCol md="2" sm="3">
            <CRow>
              <label class="col-md-12 mb-1 text-gray">Course Type</label>
              <p class="col-md-12 mb-1 text-black">
                {{ course.course_test_type }}
              </p>
            </CRow>
          </CCol>
          <CCol md="2" sm="3" v-if="course.healthcare_sector">
            <CRow>
              <label class="col-md-12 mb-1 text-gray">Healthcare Sector/Department</label>
              <p class="col-md-12 mb-1 text-black">
                {{ course.healthcare_sector | startCase }}
              </p>
            </CRow>
          </CCol>
          <CCol md="2" sm="3" v-else>
            <CRow>
              <label class="col-md-12 mb-1 text-gray">Healthcare Sector/Department</label>
              <p class="col-md-12 mb-1 text-black">
                {{ course.departmentName  || '--'}}
              </p>
            </CRow>
          </CCol>
          <CCol md="2" sm="3" v-if="course.courseLevel">
            <CRow>
              <label class="col-md-12 mb-1 text-gray">Course Level</label>
              <p class="col-md-12 mb-1 text-black">
                {{ course.courseLevel }}
              </p>
            </CRow>
          </CCol>
          <CCol md="2" sm="3" v-if="course.candidateType">
            <CRow>
              <label class="col-md-12 mb-1 text-gray">Student Type</label>
              <p class="col-md-12 mb-1 text-black">
                {{ course.candidateType }}
              </p>
            </CRow>
          </CCol>
          <CCol md="1" sm="3" v-if="course.language">
            <CRow>
              <label class="col-md-12 mb-1 text-gray">Language Delivery</label>
              <p class="col-md-12 mb-1 text-black">{{ course.language }}</p>
            </CRow>
          </CCol>
          <CCol md="3" sm="3" v-if="course.cefr_level">
            <CRow>
              <label class="col-md-12 mb-1 text-gray">CEFR Level</label>
              <p class="col-md-12 mb-1 text-black">{{ course.cefr_level }}</p>
            </CRow>
          </CCol>
          <CCol md="2" sm="3" v-if="course.learning_hours">
            <CRow>
              <label class="col-md-12 mb-1 text-gray">Learning Hrs</label>
              <p class="col-md-12 mb-1 text-black">{{ course.learning_hours }}</p>
            </CRow>
          </CCol>
          <CCol md="1" sm="3">
            <CRow>
              <label class="col-md-12 mb-1 text-gray">Reference</label>
              <p class="col-md-12 mb-1 text-black">{{ course.course_ref || '--' }}</p>
            </CRow>
          </CCol>
          <CCol md="2" sm="3">
            <CRow>
              <label class="col-md-12 mb-1 text-gray">Course Title</label>
              <p class="col-md-12 mb-1 text-black">{{ course.course_title }}</p>
            </CRow>
          </CCol>
          <!-- <CCol md="2" sm="3">
            <CRow>
              <label class="col-md-12 mb-1 text-gray">Duration Access</label>
              <p class="col-md-12 mb-1 text-black">
                {{ course.duration_months }}
              </p>
            </CRow>
          </CCol> -->
          <CCol md="1" sm="3">
            <CRow>
              <label class="col-md-12 mb-1 text-gray">CPD Points</label>
              <p class="col-md-12 mb-1 text-black">{{ course.cpd_points || '--' }}</p>
            </CRow>
          </CCol>
          <CCol md="1" sm="3" v-if="course.options_count">
            <CRow>
              <label class="col-md-12 mb-1 text-gray">Lessons</label>
              <p class="col-md-12 mb-1 text-black">{{ course.options_count }}</p>
            </CRow>
          </CCol>
          <CCol md="2" sm="3" v-if="course.modules_count">
            <CRow>
              <label class="col-md-12 mb-1 text-gray">No.of Modules</label>
              <p class="col-md-12 mb-1 text-black">
                {{ course.modules_count }}
              </p>
            </CRow>
          </CCol>
          <CCol md="2" sm="3" v-if="course.semesters_count">
            <CRow>
              <label class="col-md-12 mb-1 text-gray">No.of Semesters</label>
              <p class="col-md-12 mb-1 text-black">
                {{ course.semesters_count }}
              </p>
            </CRow>
          </CCol>
          <CCol md="2" sm="3" v-if="course.study_mode">
            <CRow>
              <label class="col-md-12 mb-1 text-gray">Study Mode</label>
              <p class="col-md-12 mb-1 text-black">
                {{ course.study_mode_name | studyMode }}
              </p>
            </CRow>
          </CCol>
        </CRow>
      </div>
      <div class="d-flex justify-content-end">
        <CButton
          class="icon mr-1"
          v-c-tooltip="'Click to view this course!'"
          @click="navToCourse(course)"
        >
          <i class="fas fa-eye" />
        </CButton>
      </div>
    </div>
  </div>
</template>
<script>
import * as _ from "lodash";
export default {
  props: {
    course: {
      type: Object,
      default: {},
    },
  },
  methods: {
    navToCourse(data) {
      if (data?.training_course_id)
        this.$router.push(`/course/${data.training_course_id}`);
    },
  },
  filters: {
    startCase(data) {
      return data ? _.startCase(data) : data;
    },
    studyMode(data) {
      return data?.length ? data.map(v=>v.study_mode).join(', '): '--'
    },
    candidateType(data) {
      return data?.length ? data.map(v=> v.candidate_type).join(', '): '--'
    }
  },
};
</script>
<style lang="scss" scoped>
.text-gray {
  color: #6f6f6f;
  font-size: 13px;
}
.w-98 {
  width: 98%;
}
</style>
