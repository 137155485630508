<template>
  <div class="course-card">
    <div class="d-flex justify-content-between">
      <div class="w-98">
        <CRow class="">
          <CCol md="2" sm="3">
            <CRow>
              <label class="col-md-12 mb-1 text-gray">Provider</label>
              <p class="col-md-12 mb-1 text-black">
                {{ course.course_test_type }}
              </p>
            </CRow>
          </CCol>
          <CCol md="1" sm="3">
            <CRow>
              <label class="col-md-12 mb-1 text-gray">Language</label>
              <p class="col-md-12 mb-1 text-black">{{ course.language }}</p>
            </CRow>
          </CCol>          
          <CCol md="1" sm="3">
            <CRow>
              <label class="col-md-12 mb-1 text-gray">Reference</label>
              <p class="col-md-12 mb-1 text-black">{{ course.course_ref }}</p>
            </CRow>
          </CCol>
          <CCol md="2" sm="3">
            <CRow>
              <label class="col-md-12 mb-1 text-gray">Title</label>
              <p class="col-md-12 mb-1 text-black">{{ course.course_title }}</p>
            </CRow>
          </CCol>
          <CCol md="2" sm="3">
            <CRow>
              <label class="col-md-12 mb-1 text-gray">CEFR Level</label>
              <p class="col-md-12 mb-1 text-black">{{ course.cefr_level }}</p>
            </CRow>
          </CCol>
          <CCol md="2" sm="3">
            <CRow>
              <label class="col-md-12 mb-1 text-gray">Delivery Mode</label>
              <p class="col-md-12 mb-1 text-black">
                {{ '--' }}
              </p>
            </CRow>
          </CCol>
          <CCol md="2" sm="3">
            <CRow>
              <label class="col-md-12 mb-1 text-gray">Learning Hrs</label>
              <p class="col-md-12 mb-1 text-black">{{ course.learning_hours }}</p>
            </CRow>
          </CCol>
        </CRow>
      </div>
      <div class="d-flex justify-content-end">
        <CButton
          class="icon mr-1"
        >
          <i class="fas fa-eye" />
        </CButton>
      </div>
    </div>
  </div>
</template>
<script>
import * as _ from "lodash";
export default {
  props: {
    course: {
      type: Object,
      default: {},
    },
  },
  methods: {
    navToCourse(data) {
      if (data?.training_course_id)
        this.$router.push(`/course/${data.training_course_id}`);
    },
  },
  filters: {
    startCase(data) {
      return data ? _.startCase(data) : data;
    },
  },
};
</script>
<style lang="scss" scoped>
.text-gray {
  color: #6f6f6f;
  font-size: 13px;
}
.w-98 {
  width: 98%;
}
</style>
