<template>
  <div class="list-course mb-4">
    <div>
      <GenericFilter
        :basicFilterlength="10"
        :filterSchema="currentPageSchema"
        :selectedFilters="selectedFilters"
        :filterDependencyObj="filterDependencyObj"
        :isFetchingFilter="isFetchingGenericFilter"
        :callback="updateRouterURL"
        :searchCallback="searchCallback"
        :filterAction="filterAction"
        :hideFavoriteBtn="true"
        :backURL="backURL"
      />
    </div>
    <div class="courses mt-4">
      <CRow>
        <CCol md="6">
          <CourseCount
            title="Courses"
            :totalCount="courseTotalCount"
            :filterCount="courseFilteredCount"
          />
        </CCol>
        <CCol md="6">
          <Pagination
            :total="courseTotalCount"
            :perPage="getCoursePaginationLimit"
            @pagechanged="pageChanged"
            :maxVisibleButtons="isMobile ? 3 : 5"
            :position="isMobile ? 'left' : 'end'"
          />
        </CCol>
      </CRow>
      <TableCourse :courses="courseListMapped" />
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import SearchBar from "@/components/reusable/GenericFilterAndSearch/searchComponent";
import sidebar from "@/components/reusable/GenericFilterAndSearch/sidebar";
import SelectedTags from "@/components/reusable/GenericFilterAndSearch/selectedTags";
import TextInput from "@/components/reusable/Fields/TextInput";
import filterSelect from "@/components/reusable/Fields/GeniricFilterSelect";
import TableCourse from "@/containers/Course/ListCourse/TableCourse";
import CourseCount from "@/components/Count";
import Pagination from "@/components/reusable/Pagination";
import { mapActions, mapGetters } from "vuex";
import {
  isObject,
  isEmptyObjectCheck,
  getFilterQueryString,
  getFilterQueryStringWithoutArrayBasedOnSchema,
  getFilterQueryStringWithoutArray,
} from "@/helpers/helper";
import * as _ from "lodash";

Vue.component("sidebar", sidebar);
Vue.component("SelectedTags", SelectedTags);
Vue.component("filterSelect", filterSelect);
Vue.component("filterInput", TextInput);

import GenericFilter from "@/components/reusable/GenericFilterAndSearch/index";
import { Role, getScope, CONSTANT as CONST } from "@/helpers/helper";

export default {
  name: "ListCourse",
  components: {
    SearchBar,
    TableCourse,
    CourseCount,
    Pagination,
    GenericFilter,
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      isFetchingFilter: false,
      sideBarShow: false,
      isMobile: false,
      collapseIndex: null,
      selectedFilters_1: {},
    };
  },
  computed: {
    ...mapGetters([
      "getCoursePaginationLimit",
      "getCourseTestTypes",
      "locationLanguageReOrder",
      "courseFilterSchema",
      "courseTotalCount",
      "courseHealthcareSector",
      "courseFilteredCount",
      "courseDeliveryMode",
      "courseList",
      "candidateType",

      "currentPageSchema",
      "selectedFilters",
      "filterDependencyObj",
      "isFetchingGenericFilter",
      "getQueryResult",
      "getOrgTypeIDFromAccessToken"
    ]),
    backURL(){
      return [Role.customerCandidate, Role.systemCandidate].includes(this.currentUserRole) ? '/home': null
    },
    isEducationalInst() {
      return this.getOrgTypeIDFromAccessToken === 18
    },
    courseTypes() {
      return (
        this.getCourseTestTypes.map((v) => ({
          code: v?.course_test_type_id,
          label: v?.course_test_type,
        })) || []
      );
    },
    filterSchema() {
      return this.courseFilterSchema;
    },
    options() {
      return {
        courseType: this.courseTypes,
        language: this.locationLanguageReOrder,
        healthcare_sector: this.courseHealthcareSector,
        delivery: this.courseDeliveryMode,
        studentType: this.candidateType,
      };
    },
    searchTerm() {
      return this.selectedFilters.courseTitle || "";
    },
    selectedFilterLength() {
      return this.selectedFilters.length;
    },
    courseListMapped() {
      const res = this.courseList.map((v) => ({
        ...v.training_course,
        modules_count: v.modules_count,
        options_count: v.options_count,
        course_test_type:
          v?.training_course?.course_test_types?.course_test_type || null,
        language: v?.training_course?.language?.language || null,
        departmentName:
          v?.training_course?.department?.department?.department_name || null,
        courseLevel: v?.training_course?.course_level?.qualification_classification || null,
        candidateType:
          v?.training_course?.candidate_types?.map(v=> v.candidate_type).join(', ') || null,
      }));
      return res;
    },
    isDisable() {
      return (propertyName) => {
        const secondLevelDependentDisable =
          this.filterSchema[propertyName]?.secondLevelDependentDisable;
        const selectInversionDependentDisable =
          this.filterSchema[propertyName]?.selectDependentDisable;
        if (secondLevelDependentDisable && secondLevelDependentDisable.length) {
          let secondLevelDepArray =
            this.filterSchema[propertyName]?.secondLevelDependentDisable;
          const dependsOn =
            this.filterSchema[propertyName]?.secondLevelDependentDisable?.[0];
          const selectedDependent = this.selectedFilters[dependsOn];

          if (Array.isArray(selectedDependent) && !selectedDependent.length) {
            return true;
          }
          if (
            isObject(selectedDependent) &&
            isEmptyObjectCheck(selectedDependent)
          ) {
            return true;
          }
        }
        if (
          selectInversionDependentDisable &&
          selectInversionDependentDisable.length
        ) {
          const selectDependentDisable =
            this.filterSchema[propertyName]?.selectDependentDisable;
          let disableCheck = false;
          selectDependentDisable.map((item) => {
            const dependsOn = item;
            const selectedDependent = this.selectedFilters[dependsOn];
            if (Array.isArray(selectedDependent) && selectedDependent.length) {
              disableCheck = true;
            }
            if (
              isObject(selectedDependent) &&
              !isEmptyObjectCheck(selectedDependent)
            ) {
              disableCheck = true;
            }
          });
          return disableCheck;
        }
        if (
          this.filterSchema[propertyName]?.conditions &&
          Array.isArray(this.filterSchema[propertyName]?.conditions) &&
          this.filterSchema[propertyName]?.conditions.length
        ) {
          let res = true;
          this.filterSchema[propertyName]?.conditions.forEach((ele) => {
            const v = ele.values || [];
            const condi = v.includes(
              this.selectedFilters[ele["field"]]?.code ||
                this.selectedFilters[ele["field"]][0]?.code
            );
            if (condi) {
              res = false;
              return;
            }
          });
          return res;
        }
        return false;
      };
    },
  },
  methods: {
    ...mapActions([
      "initCourseOptions",
      "getCourseListCount",
      "getCourseList",
      "getCourseListTotalCount",


      "setCurrentPageSchema",
      "initAllFilters",
      "updateSelectedFilter",
      "clearAllSelectedFilters",
      "updateSelectedFilterFromFilterCode",
    ]),
    searchCallback(payload) {
      payload["type"] = "searchTerm";
      this.updateRouterURL(payload);
    },
    removeFilter(name) {
      delete this.selectedFilters_1[name];
      this.updateRouterURL();
    },
    toggleSideBar() {
      this.sideBarShow = !this.sideBarShow;
    },
    clearAllFilters() {
      this.selectedFilters_1 = [];
      this.updateRouterURL();
    },
    showCollapse(index) {
      if (this.collapseIndex === index) {
        this.collapseIndex = null;
        return;
      }
      this.collapseIndex = index;
    },
    getDependentOptions(prop) {
      return this.options[prop] || [];
    },
    onInput(name, value, type) {
      Vue.set(this.selectedFilters_1, name, value);
      this.updateRouterURL();
    },
    getQueryString() {
      const selectedFilters = this.selectedFilters_1;
      const selectedData = {};
      const queryPayload = {};
      for (const item in selectedFilters) {
        let data = selectedFilters[item];
        const query =
          item === "courseTitle"
            ? "course_title"
            : this.courseFilterSchema[item].queryKey;
        if (typeof data === "string" && data) {
          selectedData[item] = [data];
          queryPayload[query] = [data];
        } else if (Array.isArray(data) && data.length) {
          const codes = data.map((val) => {
            if (isObject(val) && !isEmptyObjectCheck(val)) {
              return val.code;
            } else {
              return val;
            }
          });
          selectedData[item] = codes;
          queryPayload[query] = codes;
        } else if (isObject(data) && !isEmptyObjectCheck(data)) {
          selectedData[item] = data["code"];
          queryPayload[query] = data["code"];
        }
      }
      const routerURL = getFilterQueryStringWithoutArray(selectedData);
      const queryURL = getFilterQueryStringWithoutArray(queryPayload);
      return { routerURL, queryURL, queryPayload };
    },
    updateRouterURL(payload) {
      let appendAction =  [this.updateSelectedFilter(payload)];
      Promise.all(appendAction).then((res) => {
        let path = `${this.$route.path}?${this.getQueryResult.routeUrl}`;
        this.$router.push({ path });
      });
    },
    filterAction() {
      this.initAllFilters().then(async () => {
        await this.clearAllSelectedFilters();
        await this.updateSelectedFilterFromFilterCode(
          this.$router.currentRoute.query
        );
        await this.getCourseList({
          query: this.getQueryResult.queryUrl
        });
      });
    },
    pageChanged(pageNumber) {
      let path = `${this.$route.path}?page=${pageNumber}`;
      const { routerURL, queryPayload } = this.getQueryString();
      if (routerURL)
        path = `${this.$route.path}?page=${pageNumber}&${routerURL}`;
      this.$router.push({ path });
      this.getCourseList({
        query: queryPayload,
        pageNumber,
        isPageChanged: true,
      });
    },
    setUpFilterSchema() {
      const schema = this.isEducationalInst ? 'courseListSchemaForIMS':'courseListSchemaForSLC'
      this.setCurrentPageSchema({ schema });
    },
  },
  mounted() {
    let isMobile = window.matchMedia(
      "only screen and (max-width: 760px)"
    ).matches;
    this.isMobile = isMobile;
    let appendAction = [this.initAllFilters()];
    appendAction.push(this.initCourseOptions());
    Promise.all(appendAction).then(async res=>{
      await this.updateSelectedFilterFromFilterCode(
        this.$router.currentRoute.query
      );
      this.getCourseListTotalCount();
      const pageNumber = parseInt(this.$router.currentRoute?.query?.page || 1);
      //Todo working on the two api calls issue
      this.getCourseList({
        query: this.getQueryResult.queryUrl,
        pageNumber,
      });
    })

  },
  created() {
    this.setUpFilterSchema();
  },
};
</script>
<style lang="scss" scoped>
.list-filter {
  display: flex;
  padding: 4px 0;
  border: 1px solid #c9cbce;
  color: #00626a;
  background-color: #f1f2f5;
  align-items: center;
  justify-content: space-between;
  p {
    font-weight: 600;
    font-size: 18px;
  }
}
</style>
