<template>
  <div class="course-table">
    <!-- <CListGroup v-if="courses.length">
      <CListGroupItem v-for="(course, index) in courses" :key="index" class="course">
        <CourseCardForCandidate v-if="isCandidateRoleFromAccessToken" :course="course"/>
        <CourseCard v-else :course="course" />
      </CListGroupItem>
    </CListGroup>
    <CRow v-else>
      <CCol class="d-flex justify-content-center">
        <h1>No Data found!</h1>
      </CCol>
    </CRow> -->
    <div class="table-responsive">
      <table class="table table-striped">
        <colgroup>
          <col span="1" width="12%" />
          <col span="1" width="10%" />
          <col span="1" width="10%" />
          <col span="1" width="10%" />
          <col span="1" width="10%" />
          <col span="1" width="14%" />
          <col span="1" width="10%" />
          <col span="1" width="10%" />
          <col span="1" width="8%" />
          <col span="1" width="5%" v-if="!isCandidateRoleFromAccessToken" />
        </colgroup>
        <thead>
          <th>Reference</th>
          <th>Course Type</th>
          <th>Healthcare Sector/Department</th>
          <th>Course Level</th>
          <th>Student Type</th>
          <th>Course Title</th>
          <th>Study Mode</th>
          <th>Language Delivery</th>
          <th>CPD Points</th>
          <th v-if="!isCandidateRoleFromAccessToken"></th>
        </thead>
        <tbody>
          <tr v-for="(course, index) in courses" :key="index">
            <td>{{ course.course_ref || "--" }}</td>
            <td>{{ course.course_test_type || "--" }}</td>
            <td>
              <span v-if="course.healthcare_sector">{{
                course.healthcare_sector | startCase
              }}</span>
              <span v-else>{{ course.departmentName || "--" }}</span>
            </td>
            <td>{{ course.courseLevel || "--" }}</td>
            <td>{{ course.candidateType || "--" }}</td>
            <td>{{ course.course_title || "--" }}</td>
            <td>{{ course.study_mode_name | studyMode }}</td>
            <td>{{ course.language || "--" }}</td>
            <td>{{ course.cpd_points || "--" }}</td>
            <td v-if="!isCandidateRoleFromAccessToken">
              <CButton
                class="icon mr-1"
                v-c-tooltip="'Click to view this course!'"
                @click="navToCourse(course)"
              >
                <i class="fas fa-eye" />
              </CButton>
            </td>
          </tr>
          <tr v-if="!courses.length">
            <td :colspan="isCandidateRoleFromAccessToken ? 9 : 10">
              <p class="text-center mt-4 text-muted">No Data Found</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CourseCard from "./CourseCard";
import CourseCardForCandidate from "./CourseCardForCandidate";
export default {
  name: "CourseTable",
  props: {
    courses: {
      type: Array,
      default: [],
    },
  },
  components: {
    CourseCard,
    CourseCardForCandidate,
  },
  data() {
    return {
      fields: [
        { key: "course_type", _style: "min-width:100px" },
        { key: "health_sector", _style: "min-width:200px" },
        { key: "language", _style: "min-width:100px;" },
        { key: "cefr_level", _style: "min-width:100px;" },
        { key: "delivery_mode", _style: "min-width:200px" },
        { key: "learning_hrs", _style: "min-width:200px" },
        { key: "reference", _style: "min-width:200px" },
        { key: "title", _style: "min-width:200px" },
        { key: "duration_access", _style: "min-width:200px" },
        { key: "cpd_points", _style: "min-width:200px" },
        { key: "lessons", _style: "min-width:200px" },
        { key: "no_modules", _style: "min-width:200px" },
      ],
    };
  },
  computed: {
    ...mapGetters(["isCandidateRoleFromAccessToken"]),
  },
  methods: {
    navToCourse(data) {
      if (data?.training_course_id)
        this.$router.push(`/course/${data.training_course_id}`);
    },
  },
  filters: {
    studyMode(data) {
      return data?.length ? data.map((v) => v.study_mode).join(", ") : "--";
    },
  },
};
</script>
